import React from "react"
import {graphql, Link} from "gatsby"
//import Img from "gatsby-image"
import Imgix from "react-imgix";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const hello = "Hello, gatsby!";

const Home = ({data}) => (
    <Layout>
        <Seo></Seo>
        <section className="hero">
            <figure>
                <Image filename="hero.jpg" alt="" style={{height: "100%"}} loading="eager" durationFadeIn={100}></Image>
            </figure>
            <div className="catch">
                <h1>{hello}<br /> the love of food.</h1>
                <p>つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、<br/>
                そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。</p>
                <p><OutboundLink href="https://ozn.pw">外部リンク計測</OutboundLink></p>
            </div>
            <div className="wave">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 229.5" fill="#fff"><path d="M1369,6.3C1222.5-12.2,1189.5,8,919.2,96.6C665,179.8,160,141.7-2,53.1v150l1371-14.2V6.3z" opacity=".53" /><path d="M1369 229.5V55.8c-9.5-2.4-19.2-4.4-28.9-5.8-196.9-29.9-203.4-15.8-503.9 82.6-219.8 72-627.6 53.2-838.2-10.5v107.4h1371z" /></svg>
            </div>
        </section>
        <section className="food">
            <div className="container">
                <h2 className="bar">Food <span>Essence</span></h2>
                <div className="details">
                    <div className="detail">
                        <figure>
                            <Image filename="fruit.jpg" alt="フルーツ"></Image>
                        </figure>
                        <h3>フルーツ</h3>
                        <p>FRUIT</p>
                        <p>甘くてすっぱくておいしい果実たち。<br />旬のフルーツを満喫します。</p>
                    </div>
                    <div className="detail">
                        <figure>
                            <Image filename="grain.jpg" alt="穀物"></Image>
                        </figure>
                        <h3>穀物</h3>
                        <p>GRAIN</p>
                        <p>食事の基本となる穀物。<br />毎日の活動のエネルギー源になります。</p>
                    </div>
                    <div className="detail">
                        <figure>
                            <Image filename="beverage.jpg" alt="飲み物"></Image>
                        </figure>
                        <h3>飲み物</h3>
                        <p>BEVERAGE</p>
                        <p>リラックスするのに欠かせない飲み物。<br />お気に入りの一杯はありますか？</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="photo">
            <h2 className="sr-only">Photo</h2>
            <figure>
                <Image filename="berry.jpg" alt="赤く熟したベリー" style={{height: "100%"}}></Image>
            </figure>
        </section>
        <section>
            <div className="container">
                <h2 className="sr-only">RECENT POSTS</h2>
                <div className="posts">
                    {data.allMicrocmsBlog.edges.map( ({ node }) => (
                        <article className="post" key={node.id}>
                            <Link to={`/blog/post/${node.slug}/`}>
                                <figure>
                                    <Imgix src={node.eyecatch.url} sizes="(max-width: 537px) 100vw, 537px" htmlAttributes={{ alt: "" }}></Imgix>
                                </figure>
                                <h3>{node.title}</h3>
                            </Link>
                        </article>
                    )  )}
                </div>
            </div>
        </section>
    </Layout>
    )
export default Home

export const query = graphql`
    query {
      allMicrocmsBlog(
        sort: {order: DESC, fields: publishDate}, 
        skip: 0, 
        limit: 4
      ) {
        edges {
          node {
            title
            id
            slug
            eyecatch {
              url
            }
          }
        }
      }
      
    }
`
